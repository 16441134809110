/* eslint-disable react/jsx-pascal-case */
/* eslint-disable import/order */
import React, { useState } from 'react';
import Step1 from './step/step-1';
import Step2 from './step/step-2-form';
import Step2_5 from './step/step-2-session';
import Step3 from './step/step-3';
import Step4 from './step/step-4';
import Step5 from './step/step-5';
import '../assets/scss/style.scss';
// import "../assets/js/main.js"
import { Dots } from 'react-activity';
import 'react-activity/dist/library.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function VersionOne(props) {
  const [createdUser, setCreatedUser] = useState(null);
  const [currentForm, setCurrentForm] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [BUselectedOptions, setBUSelectedOptions] = useState([]);

  const [isSpeaker, setIsSpeaker] = useState('');
  const [isLocal, setIsLocal] = useState('');
  const [asd, setAsd] = useState('');
  const [pds, setPds] = useState('');
  const [pdsNumber, setPdsNumber] = useState('');
  const [asdEmail, setAsdEmail] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [proSuffix, setProSuffix] = useState('');
  const [suffix, setSuffix] = useState('');
  const [certName, setCertName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [prcNumber, setPrcNumber] = useState('');
  const [country, setCountry] = useState('');
  const [institution, setInstitution] = useState('');

  const [hasUpload, setHasUpload] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [letterUrl, setLetterUrl] = useState('');
  const [sessions, setSessions] = useState([]);
  const [fellowship, setFellowship] = useState(false);
  const [pmaNumber, setPmaNumber] = useState('');
  const [category, setCategory] = useState('');

  const handleNext = (step, value) => {
    switch (step) {
      case 'step_2':
        setCurrentForm(1)
        setSelectedOptions(value.filter(Boolean));
        break;

      case 'step_2_5':
        return setCurrentForm(0.5);

      case 'step_3':
        return setCurrentForm(2);

      default:
        return setCurrentForm(currentForm + 1);
    }
  };

  const handleBack = (step) => {
    setPdsNumber('')
    setEmail('')
    switch (step) {
      case 'step_2_5':
        return setCurrentForm(0.5);

      case 'step_2':
        return setCurrentForm(1);

      case 'fromtemplate':
        // console.log(selectedOptions)
        setSelectedOptions([])
        setCurrentForm(currentForm - 1);
        return

      default:
        return setCurrentForm(currentForm - 1);
    }
  };

  return (

    <div className="wrapper">
      {isLoading
        && (
          <div style={{
            backgroundColor: '#000000a8', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', flexDirection: 'column', zIndex: 20000, position: 'absolute'
          }}
          >
            <h6 style={{ color: 'white', margin: 20 }}>LOADING...</h6>
            <Dots color="white" size={50} />
          </div>
        )}

      <Container fluid>
        <Row>
          <Col xs={3} style={{ paddingLeft: 0 }}>
            <div className="image-holder">
              <img src="https://register.govirtual.ph/wp-content/uploads/2025/02/vertical-01.jpg" alt="" style={{
                height: '100vh',
                width: "100%",
                objectPosition: 'bottom'
              }} />
            </div>
          </Col>
          <Col xs={9} style={{ paddingRight: 60, paddingLeft: 60, paddingBottom: 60,overflowY: 'scroll', height: '100vh' }}>
            <div className="multisteps-form__form">
              <div className="form-area position-relative">
                <Step1
                  pds={pds}
                  isSpeaker={isSpeaker}
                  pdsNumber={pdsNumber}
                  asdEmail={asdEmail}
                  asd={asd}
                  isLocal={isLocal}
                  currentForm={currentForm}
                  handleNext={handleNext}
                  setIsSpeaker={setIsSpeaker}
                  setIsLocal={setIsLocal}
                  setAsd={setAsd}
                  setPds={setPds}
                  setPdsNumber={setPdsNumber}
                  setAsdEmail={setAsdEmail}
                  setHasUpload={setHasUpload}

                  setFirstName={setFirstName}
                  setLastName={setLastName}
                  setEmail={setEmail}
                />
                <Step2
                  selectedOptions={selectedOptions}
                  country={country}
                  setFirstName={setFirstName}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  pdsNumber={pdsNumber}
                  setFellowship={setFellowship}
                  setLastName={setLastName}
                  setMiddleName={setMiddleName}
                  setProSuffix={setProSuffix}
                  setSuffix={setSuffix}
                  setCertName={setCertName}
                  setEmail={setEmail}
                  setCountry={setCountry}
                  setInstitution={setInstitution}
                  setPrcNumber={setPrcNumber}
                  setPdsNumber={setPdsNumber}
                  setPmaNumber={setPmaNumber}
                  currentForm={currentForm}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  isLocal={isLocal}
                  hasUpload={hasUpload}
                  setLetterUrl={setLetterUrl}

                  setCategory={setCategory}

                />
                <Step2_5
                  currentForm={currentForm}
                  setSessions={setSessions}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
                <Step3
                  data={
                    {
                      firstName, lastName, middleName, proSuffix, suffix, certName, email, country, institution, prcNumber, pdsNumber, pmaNumber, sessions, selectedOptions, fellowship
                    }
                  }
                  currentForm={currentForm}
                  isLocal={isLocal}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
                <Step4
                  data={
                    {
                      firstName, lastName, middleName, proSuffix, suffix, certName, email, country, institution, isLocal, asd, pds, prcNumber, letterUrl, pdsNumber, selectedOptions, pmaNumber, sessions, fellowship, category
                    }
                  }
                  isLocal={isLocal}
                  setCreatedUser={setCreatedUser}
                  setIsLoading={setIsLoading}
                  asd={asd}
                  pds={pds}
                  category={category}
                  setPassword={setPassword}
                  currentForm={currentForm}
                  setCategory={setCategory}
                  setPaymentType={setPaymentType}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
                <Step5 password={password} currentForm={currentForm} handleNext={handleNext} handleBack={handleBack} data={{ certName, createdUser, category, firstName, lastName, email, selectedOptions }} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  );
}
